@import '../../../style/varibale';

.app__auth {
    background: linear-gradient(360deg, rgba(205, 57, 57, 0.5) -180%, rgba(217, 217, 217, 0) 100%);

    background-size: cover;
    background-position: center center;
    display: flex;
    justify-content: center;
    min-height: 100vh;

    .app__login {
        width: 40%;
        
        @media screen and (max-width:1000px) {
            width: 80%;
        }
        @media screen and (max-width:767px) {
            width: 100%;
        }

        .app__login-left {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 100% !important;
            // background-color: #fff;

            img {
                width: 220px;
                height: 150px;
                object-fit: contain;
            }

            .input_form {
                display: flex;
                flex-direction: column;
                gap: 5px;
                margin-top: 15px;
                .p-inputtext{
                    border-radius: 50px;
                }
                .p-input-icon-left > i:first-of-type, .p-input-icon-left > svg:first-of-type, .p-input-icon-left > .p-input-prefix {
                    left: 1.75rem;
                }
                .p-input-icon-left > .p-inputtext {
                    padding-left: 3.5rem;
                }

                label {
                    align-self: flex-start;
                    font-size: .913rem;
                    font-weight: 500;
                    margin: 0;
                    padding-bottom: 4px;
                    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important;
                    -webkit-font-smoothing: antialiased !important;
          
                }

                .error__handel {
                    color: $colorRed-1;
                }


                .password__input {
                    position: relative;

                    .password__show {
                        position: absolute;
                        right: 0;
                        border-radius: 25px;
                        height: 100%;
                        background-color: transparent;
                        color: #4A4A4A;
                        border: none;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-color: white !important;
                    }
                }
            }

            .app__login-btn {
                height: 52px;
                margin: 1rem auto 0;
                width: 52px;
                border-radius: 50%;
                outline: none;
                border: none;
                background-color: $mainColor;
                display: flex;
                align-items: center;
                justify-content: center;

                // margin-top: 20px !important;
                .app__login-btn-icons path {
                    stroke: #F9F9F9;
                    color: #FFFFFF;
                }
            }
        }
    }

    .avatar {
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        // align-items: end;

        @media screen and (max-width:991px) {
            display: none !important;
        }
    }
}

.login__form {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    padding-bottom: 40PX;
}

.opisity {
    opacity: 0.5;
}

.opisity1 {
    opacity: 1 !important;
}

.bg__login {
    background-color:#d42b1f0b;
    padding: 30px 0;
    border-radius: 10px;
}

.bg__login-icons {
    padding: 15px;
}

.login__form_inputs {
    width: 75%;

    a {
        align-self: flex-start;
        font-size: .913rem;
        font-weight: 500;
        margin: 0;
        padding-bottom: 4px;
        font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important;
        -webkit-font-smoothing: antialiased !important;
        color: #E4E6EB;
    }

    @media screen and (max-width:500px) {
        width: 85% !important;
    }
}

.spinner-border {
    color: white !important;
    width: 25px !important;
    height: 25px !important;
}

.password_open {
    position: relative;

    .app__login-btn-icons {
        position: absolute;
        top: 50%; 
        right: 20px;
        cursor: pointer;
        width: 20px !important;

    }
}
.app__login{
    .pi-eye-slash,
    .pi-eye {
        color: #000;
    }
 
    
     
}


.error_handel{
    color: red;
    margin: 0 20px;
}