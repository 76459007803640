@import './varibale';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,500&family=Roboto+Mono:wght@200;300;400;500;600;700&family=Roboto:wght@700;900&family=Tajawal:wght@300;400;500;700;800;900&display=swap');

.color-red {
  color: $mainColor !important;
}

.remove_image {
  padding: 7px !important;
  border-radius: 50%;
  color: #fff !important;
  background-color: rgb(255, 77, 77) !important;
  border: $mainColor !important;

  i {
    font-size: 13px;

  }
}

.p-autocomplete {
  height: 40px;
  .p-button {
    background-color: $mainColor !important;
    border:$mainColor  ;
  }

}

.dropdown_filter {
  .p-dropdown-label {
    padding: 0;
    margin: 0;
  }
}

img {
  object-fit: contain;
}

.secondColor {
  color: $secondColor !important;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider,
.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: $secondColor !important;
  background: $secondColor !important;
}

.Checkbox_active {
  .p-checkbox .p-checkbox-box.p-highlight {
    border-color: $mainColor !important;
    background: $mainColor !important;
  }

}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: $secondColor !important;
  background: $secondColor !important;
}

.p-checkbox .p-checkbox-box {
  width: 20px !important;
  height: 20px !important;
}

a {
  text-decoration: none !important;
}

.p-datatable .p-datatable-thead>tr>th {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #dee2e6;
  border-width: 0 0 1px 0;
  font-weight: 500 !important;
  color: #606871;
  background: #f8f9fa;
  transition: box-shadow 0.2s;
}

.input__Col {
  width: 100%;
}

// pagr not found
.app__error {
  height: calc(100vh - 70px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.notfound-image {
  @media screen and (max-width:767px) {
    width: 410px;

  }
}

.notfound-image {
  width: 100px !important;
}

.image_view {
  max-height: 250px !important;
  border-radius: 10px !important;
}

.p-datatable-striped {
  td {
    text-align: center !important;
  }
}

.p-inputtext.p-inputtext-sm {
  font-size: 15px !important;
}

.colors_tabel {
  display: flex;
  width: 15px !important;
  height: 15px !important;
  border-radius: 50%;
}

.galleria-container {
  .p-galleria-mask {
    z-index: 999999999999999999 !important;
    background-color: rgba($color: #000000, $alpha: 50%);
  }

  .p-galleria-mask .p-galleria-item-nav {
    position: fixed;
    top: 50%;
    margin-top: -0.5rem;
    z-index: 999999999999999999999 !important;

  }
}


.upload_image {
  img {
    width: 200px;
    cursor: pointer;

    @media screen and (width < 767px) {
      width: 150px;
    }
  }

  label {
    width: 100%;
    text-align: center;
    margin-right: 30px;
  }
}

.thumbnail_images {
  img {
    width: 100px;
    height: 100px;
    object-fit: contain;
  }
}

.p-button-main {
  .p-button {
    border: transparent;
    transition: all 0.5s;
  }
}

.btn_gapAr {
  .p-button {
    .p-button-icon {
      margin-top: 2px;
    }

    border: transparent;
    transition: all 0.5s;
  }
}

[dir="ltr"] .p-dropdown-item {
  direction: rtl;
}

.btn__porfile {
  background: $secondColor;
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 !important;
  color: #fff;
}


.Calendar_payment {
  @media screen and (width <=767px) {
    margin-top: 20px;
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    margin-top: 20px;

  }

  .p-inputtext {
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none !important;
  }

  .p-calendar-w-btn-right .p-datepicker-trigger {
    border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1.003px solid #D1D1D1;
  }

  .p-button {
    color: $mainColor;
    background: #FFF;
    border: 1.003px solid #D1D1D1;
    border-right: none !important;

    &:hover {
      background: $secondColor !important;
      border: $secondColor !important;

    }

  }
}

.Calendar_paymentEn {
  @media screen and (width <=767px) {
    margin-top: 20px;
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    margin-top: 20px;

  }

  .p-inputtext {
    border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-right: none !important;
  }

  .p-calendar-w-btn-left .p-datepicker-trigger {
    border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border: 1.003px solid #D1D1D1;
  }

  .p-button {
    color: $mainColor;
    background: #FFF;
    border: 1.003px solid #D1D1D1;
    border-left: none !important;

    &:hover {
      background: $secondColor !important;
      border: $secondColor !important;
    }
  }
}

.btn_gapAr_multi_img {
  .p-button {
    span {

      color: #fff !important;
    }

    background: #FFF;
    border: 1.003px solid #FCB040 !important;
    border-left: none !important;
    background: #FCB040 !important;

    &:hover {
      border: #FCB040 !important;

    }
  }

}

.location_branch {
  .p-button {
    svg {
      path {
        fill: #22C55E;
      }
    }
  }
}

.location_branch_map_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  .p-button-label {
    margin-bottom: 4px;
  }

}

.p-tag {
  cursor: pointer;
  user-select: none;
}

.btn_submit {
  .p-button {
    background-color: $secondColor !important;
    border-color: $secondColor !important;
  }
}

// class=" p-component p-inputwrapper p-inputtext-sm w-full h-100 p-calendar-w-btn p-calendar-w-btn-right p-inputwrapper-focus"
.filter_by_date {
  .p-calendar {
    height: 43px;
  }
}

.client_summary {
  background-color: rgba($color: $mainColor, $alpha: 0.1);

  .icons {
    i {
      color: $mainColor;
      font-size: 30px;
    }
  }

  .content {
    .header {
      color: #37474F;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .number {
      color: #000;
      font-size: 18px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
    }

  }
}

.Sales_summary1 {
  background-color: rgba($color: #FCB040, $alpha: 0.1);

  .icons {
    i {
      color: $mainColor;
      font-size: 30px;
    }
  }
}

.Sales_summary2 {
  background-color: rgba($color: $secondColor, $alpha: 0.1);

  .icons {
    i {
      color: $secondColor;
      font-size: 30px;
    }
  }
}

.p-image-mask {
  z-index: 9999999999 !important;
}

.image_prime {
  img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
  }
}

.image_prime2 {
  img {
    border-radius: 50%;
    object-fit: cover !important;
  }
}